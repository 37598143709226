import React, { useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import { useAuthState } from 'react-firebase-hooks/auth';

const ChatContext = React.createContext(null);

firebase.initializeApp({
  apiKey: 'AIzaSyAw4I8engwd4Lfodxs4bAHGXWiPdejAC44',
  authDomain: 'super-chat-app-fc114.firebaseapp.com',
  projectId: 'super-chat-app-fc114',
  storageBucket: 'super-chat-app-fc114.appspot.com',
  messagingSenderId: '157999090798',
  appId: '1:157999090798:web:0cbcab7a13b96bff7aaa7f',
  measurementId: 'G-89TG6VML74',
});

export function ChatProvider({ children }) {
  const firestore = firebase.firestore();
  const auth = firebase.auth();
  const [user] = useAuthState(auth);

  return (
    <ChatContext.Provider value={{ firestore, auth, user }}>
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error(
      'useChat must be used within a ChatProvider. Wrap a parent component in <ChatProvider> to fix this error.'
    );
  }
  return context;
}

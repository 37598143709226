import React from 'react';
import SignIn from './components/SignIn';
import ChatRoom from './components/ChatRoom';
import SignOut from './components/SignOut';
import { useChat } from './components/chatContext';
import './App.css';

const App = () => {
  const { user } = useChat();

  return (
    <div className='App'>
      <header className='App-header'>
        <h1>⚛️🔥💬</h1>
        <SignOut />
      </header>
      <section>{user ? <ChatRoom /> : <SignIn />}</section>
    </div>
  );
};

export default App;

import React from 'react';
import { useChat } from './chatContext';

const SignOut = () => {
  const { auth } = useChat();

  // eslint-disable-next-line react/button-has-type
  return (
    auth.currentUser && <button onClick={() => auth.signOut()}>Sign Out</button>
  );
};

export default SignOut;

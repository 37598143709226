import React from 'react';
import firebase from 'firebase/app';
import { useChat } from './chatContext';

const SignIn = () => {
  const { auth } = useChat();

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

  // eslint-disable-next-line react/button-has-type
  return <button onClick={signInWithGoogle}>SignIn with Google</button>;
};

export default SignIn;
